import { type ParentComponent } from "solid-js";
import { ShopContext } from "~/utils/contexts";
import { createStore } from "solid-js/store";
import { makePersisted } from "@solid-primitives/storage";

type ListConfigType =
  | "size"
  | "color"
  | "category"
  | "finish"
  | "shape"
  | "price"
  | "availableAs"
  | "colors";

export type ListConfigProductDetail = {
  type: ListConfigType;
  label: string;
  enabled: boolean;
};

const gridConfig = {
  prodsPerRow: 4,
};

const listConfig: {
  mainImages: Record<string, number>;

  options: ListConfigProductDetail[][];
} = {
  mainImages: {
    moulding: 3,
    photoFrame: 2,
    galleryFrame: 1,
  },
  options: [
    [
      { type: "size", label: "Size", enabled: true },
      { type: "color", label: "Colour", enabled: true },
      { type: "category", label: "Category", enabled: true },
      { type: "finish", label: "Finish", enabled: true },
      { type: "shape", label: "Shape", enabled: true },
      { type: "price", label: "Price", enabled: true },
    ],
    [
      { type: "availableAs", label: "Available As", enabled: true },
      { type: "colors", label: "Colors", enabled: true },
    ],
  ],
};

export type ShopConfigType = {
  productDisplayPreference: "list" | "grid";
  gridConfig: typeof gridConfig;
  listConfig: typeof listConfig;
};

const shopConfig: ShopConfigType = {
  listConfig,
  gridConfig,
  productDisplayPreference: "grid",
};

export const ShopContextProvider: ParentComponent = (props) => {
  const [localShopConfig, setLocalShopConfig] = makePersisted(
    createStore<ShopConfigType>(shopConfig),
    { name: "test-shop-config2" }
  );

  return (
    <ShopContext.Provider
      value={{
        shopConfig: localShopConfig,
        setShopConfig: setLocalShopConfig,
      }}
    >
      {props.children}
    </ShopContext.Provider>
  );
};
